.hero {
  position: relative;
  overflow: hidden;
  padding-top: 20rem;
  padding-bottom: 13rem;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: -webkit-image-set(
      url(../img/hero/bg@2x.png) 2x,
      url(../img/hero/bg@1x.png) 1x
    );
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--clr-default-100);

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 67rem;

    @media (max-width: em(1500)) {
      margin-right: 3rem;
      max-width: 60rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      max-width: initial !important;
    }
  }

  &__title {
    font-weight: 700;
    font-size: fluid-font(6.8rem, 3.6rem);
    line-height: 100%;

    @media (max-width: em(575)) {
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 8rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__image {
    position: absolute;
    right: -19.5rem;
    bottom: -26rem;
    width: 68rem;
    height: 103rem;

    &::before {
      content: "";
      position: absolute;
      right: -18rem;
      bottom: 12.5rem;
      z-index: -1;
      width: 104.6rem;
      height: 85rem;
      background-image: url(../img/hero/avalon-chips.svg);
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(1500)) {
        display: none;
      }
    }

    @media (max-width: em(1500)) {
      position: initial;
      width: initial;
      height: initial;
      max-width: 54rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-bottom: 6rem !important;
      margin-left: auto;
      max-width: 25rem !important;
    }
  }

  @media (max-width: em(1500)) {
    padding-top: 20rem;
    padding-bottom: 10rem;
    height: initial;
  }

  @media (max-width: em(1200)) {
    padding-top: 10rem !important;
  }

  @media (max-width: em(992)) {
    // padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  @media (max-width: em(575)) {
    padding-top: 8rem !important;
    padding-bottom: 6rem !important;
  }
}
