.payment {
  padding-bottom: 16rem;

  @media (max-width: em(992)) {
    padding-bottom: 8rem;
  }

  @media (max-width: em(575)) {
    padding-bottom: 5rem;
  }

  &__wrapper {
    &.decor {
      &::before, &::after {
        height: calc(100% + 32rem);

        @media (max-width: em(992)) {
          height: calc(100% + 16rem);
        }

        @media (max-width: em(575)) {
          height: calc(100% + 10rem);
        }
      }
    }
  }

  &__title {
    margin-bottom: 4rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    margin-bottom: 4rem;
    max-width: 117rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-bottom: 6rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }

    &--bottom {
      margin-bottom: 1.5rem;
    }
  }

  &__table {
    overflow: auto;
    margin-bottom: 2rem;
    border-radius: 1.6rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
      color: var(--clr-default-100);

      thead {
        th {
          padding-top: 1.6rem;
          padding-right: 3rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          font-weight: 500;
          font-size: 1.8rem;
          text-align: start;

          &:first-child {
            min-width: 27rem;

            @media (max-width: em(992)) {
              min-width: 20rem;
            }
          }

          @media (max-width: em(575)) {
            padding-right: 2rem;
            padding-left: 1.6rem;
          }
        }
      }

      tbody {
        td {
          vertical-align: top;
          padding-top: 1.6rem;
          padding-right: 3rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          line-height: 135%;

          @media (max-width: em(575)) {
            padding-right: 2rem;
            padding-left: 1.6rem;
          }
        }
      }
    }
  }

  &__list {
    padding-left: 1rem;
  }

  &__item {
    position: relative;
    padding-left: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 135%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 1rem;
      border-radius: 50%;
      width: 0.3rem;
      height: 0.3rem;
      background-color: var(--clr-default-900);
    }
  }
}
