.service {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  color: var(--clr-default-100);

  &::before, &::after {
    content: "";
    position: absolute;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-image-set(
      url(../img/service/bg@2x.jpg) 2x,
      url(../img/service/bg@1x.jpg) 1x
    );
  }

  &::after {
    right: 0;
    bottom: 0;
    width: 113rem;
    height: 64rem;
    background-image: -webkit-image-set(
      url(../img/service/avalon-warrior@2x.png) 2x,
      url(../img/service/avalon-warrior@2x.png) 1x
    );

    @media (max-width: em(1200)) {
      display: none;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(1200)) {
      flex-direction: column;
    }
  }

  &__content-wrapper {
    @media (max-width: em(1200)) {
      margin-bottom: 9rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 7rem;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 77rem;

    &:not(:last-child) {
      margin-bottom: 10rem;

      @media (max-width: em(992)) {
        margin-bottom: 5rem;
      }
    }

    @media (max-width: em(1200)) {
      margin-right: initial;
      max-width: initial;
    }
  }

  &__title {
    margin-bottom: 3rem;
    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__text {
  }

  &__link {
    margin-top: auto;
    margin-bottom: 9rem;
    height: max-content;

    @media (max-width: em(1200)) {
      margin-top: initial;
      margin-right: auto;
      margin-bottom: 5rem;
      margin-left: auto;
    }
  }

  @media (max-width: em(992)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
