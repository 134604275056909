.header {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 16rem;

  @media (max-width: em(1200)) {
    min-height: 12rem;
  }

  @media (max-width: em(992)) {
    min-height: 8rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
