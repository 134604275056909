.info {
  &__subtitle {
    margin-bottom: 4rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    border-radius: 1.4rem;
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
      color: var(--clr-default-100);

      td {
        vertical-align: top;
        border-bottom: 0.1rem solid var(--clr-primary-400);
        padding-top: 1rem;
        padding-right: 1.2rem;
        padding-bottom: 1rem;
        padding-left: 1.2rem;
        line-height: 135%;

        &:first-child {
          min-width: 27rem;
          font-weight: 500;
          font-size: 1.8rem;

          @media (max-width: em(992)) {
            min-width: 20rem;
          }

          @media (max-width: em(575)) {
            min-width: 15rem;
          }
        }

        @media (max-width: em(575)) {
          min-width: 50rem;
        }
      }
    }

    @media (max-width: em(575)) {
      padding-top: 2rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
    }
  }
}
