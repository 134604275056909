.live {
  &__wrapper {
    display: flex;
    align-items: center;

    @media (max-width: em(992)) {
      display: block;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 47rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      max-width: initial;
    }
  }

  &__titles {
    margin-bottom: 4rem;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__item {
    position: relative;
    padding-left: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
    color: var(--clr-default-800);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.3rem;
      height: 0.3rem;
      background-color: var(--clr-default-800);
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__image {
    max-width: 67rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-bottom: 3rem;
      margin-left: auto;
    }
  }
}
