.registration {
  padding-bottom: 16rem;

  @media (max-width: em(992)) {
    padding-bottom: 8rem;
  }

  @media (max-width: em(575)) {
    padding-bottom: 5rem;
  }

  &__wrapper {
    display: flex;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    flex-shrink: 0;
    margin-right: 3rem;
    max-width: 67rem;

    @media (max-width: em(1620)) {
      flex-shrink: initial;
      max-width: 60rem;
    }

    @media (max-width: em(1200)) {
      max-width: 55rem !important;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__title {
  }

  &__text {
    margin-bottom: 4rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    list-style: decimal;
    padding-left: 2rem;
  }

  &__item {
    line-height: 135%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__sub-list {
   margin-top: 2rem;
  }

  &__sub-item {
    position: relative;
    padding-left: 1.5rem;
    line-height: 135%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.3rem;
      height: 0.3rem;
      background-color: var(--clr-default-900);
      transform: translateY(-50%);
    }
  }

  &__image {
    z-index: 1;
    flex-shrink: 0;
    max-width: 69.5rem;

    img {
      border-radius: 1.4rem;
    }

    @media (max-width: em(1620)) {
      flex-shrink: initial;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .decor {
    &::before, &::after {
      height: calc(100% + 32rem);
    }
  }
}

