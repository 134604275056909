.benefits {
  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 4rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    display: flex;
    padding-top: 2rem;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__list {
    max-width: 57rem;

    &:first-child {
      margin-right: 3rem;

      @media (max-width: em(767)) {
        margin-right: initial;
        margin-bottom: 5rem;
      }
    }

    @media (max-width: em(767)) {
      max-width: initial;
    }
  }

  &__list-title {
    position: relative;
    margin-bottom: 5rem;
    padding-left: 8rem;
    font-family: "Cormorant SC";
    font-weight: 700;
    font-size: fluid-font(2.8rem, 1.8rem);

    &--pros,
    &--cons {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 6rem;
        height: 6rem;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }

    &--pros {
      &::before {
        background-image: url(../img/benefits/pros-title.svg);
      }
    }

    &--cons {
      &::before {
        background-image: url(../img/benefits/cons-title.svg);
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 4rem;
    }
  }

  &__list-items {
  }

  &__list-item {
    font-weight: 500;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 135%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &--pros {
      position: relative;
      padding-left: 2.5rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 1.9rem;
        height: 1.9rem;
        background-image: url(../img/benefits/pros-item.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }
  }
}
