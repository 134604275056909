.bonus-btn {
  z-index: 1;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30rem;
    padding-right: 2rem;
    padding-left: 2rem;
    width: max-content;
    min-width: 15rem;
    min-height: 6rem;
    max-width: 25rem;
    font-weight: 500;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 135%;
    text-align: center;
    text-transform: uppercase;
    color: var(--clr-default-200);
    background-color: var(--clr-primary-500);
    transition: background-color var(--transition);

    @media (max-width: em(992)) {
      min-height: 5rem;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #e7471a;
        transition: background-color var(--transition);
      }
    }
  }

  @media (max-width: em(1200)) {
    margin-right: 3rem;
    margin-left: auto;
  }

  @media (max-width: em(575)) {
    margin-right: initial;
    margin-left: initial;
  }
}
