/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "AlegreyaSans";
  src: url("../fonts/AlegreyaSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AlegreyaSans";
  src: url("../fonts/AlegreyaSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CormorantSC";
  src: url("../fonts/CormorantSC-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Alegreya Sans", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: #F2EBCF;
  --clr-default-300: #f0dbaa;
  --clr-default-800: #111111;
  --clr-default-900: #000;
  --clr-primary-400: rgba(255, 255, 255, 0.15);
  --clr-primary-500: #C9340C;
  --clr-primary-700: #1E0802;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-800);
  background-color: var(--clr-default-200);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 16rem;
}

.title {
  margin-bottom: 2.5rem;
  font-family: "Cormorant SC";
  font-weight: 700;
  font-size: clamp(
    2.4rem,
    1.5272727273rem + 2.7272727273vw,
    4.8rem
  );
}

.description {
  font-weight: 400;
  line-height: 135%;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 26rem;
  min-height: 7rem;
  max-width: 26rem;
  font-family: "Cormorant SC";
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.5818181818rem + 0.6818181818vw,
    2.4rem
  );
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-500);
  transition: background-color var(--transition);
}

.main__link::after {
  content: "";
  position: absolute;
  left: -8px;
  top: -29px;
  width: calc(100% + 1.5rem);
  height: calc(100% + 7.5rem);
  background-image: url(../img/ui/btn-bg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.decor {
  position: relative;
}

.decor:before,
.decor::after {
  content: "";
  position: absolute;
  left: -13.5rem;
  top: -16rem;
  width: 9rem;
  height: calc(100% + 16rem);
  background-image: url(../img/ui/decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.decor::after {
  left: initial;
  right: -13.5rem;
}

.image-border img {
  border: 0.2rem solid var(--clr-default-300);
  border-radius: 1.4rem;
}

.bonus-btn {
  z-index: 1;
}

.bonus-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 15rem;
  min-height: 6rem;
  max-width: 25rem;
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  line-height: 135%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-500);
  transition: background-color var(--transition);
}

.menu {
  z-index: 1;
  margin-right: 2rem;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-200);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item a {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 135%;
  color: var(--clr-default-200);
}

.menu__item:not(:last-child) {
  margin-right: 3rem;
}

.logo {
  z-index: 1;
  margin-right: 2rem;
  max-width: 11rem;
}

.burger {
  display: none;
}

.header {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 16rem;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__wrapper {
  display: flex;
}

.hero {
  position: relative;
  overflow: hidden;
  padding-top: 20rem;
  padding-bottom: 13rem;
  height: 100vh;
}

.hero::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: -webkit-image-set(url(../img/hero/bg@2x.png) 2x, url(../img/hero/bg@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.hero__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--clr-default-100);
}

.hero__content {
  max-width: 67rem;
}

.hero__title {
  font-weight: 700;
  font-size: clamp(
    3.6rem,
    2.4363636364rem + 3.6363636364vw,
    6.8rem
  );
  line-height: 100%;
}

.hero__text {
  margin-bottom: 8rem;
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__link {
  margin-bottom: 5rem;
}

.hero__image {
  position: absolute;
  right: -19.5rem;
  bottom: -26rem;
  width: 68rem;
  height: 103rem;
}

.hero__image::before {
  content: "";
  position: absolute;
  right: -18rem;
  bottom: 12.5rem;
  z-index: -1;
  width: 104.6rem;
  height: 85rem;
  background-image: url(../img/hero/avalon-chips.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.info__subtitle {
  margin-bottom: 4rem;
}

.info__subtitle p:not(:last-child) {
  margin-bottom: 2rem;
}

.info__table {
  overflow: auto;
  border-radius: 1.4rem;
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  background-color: var(--clr-primary-700);
}

.info__table table {
  border-collapse: collapse;
  color: var(--clr-default-100);
}

.info__table table td {
  vertical-align: top;
  border-bottom: 0.1rem solid var(--clr-primary-400);
  padding-top: 1rem;
  padding-right: 1.2rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  line-height: 135%;
}

.info__table table td:first-child {
  min-width: 27rem;
  font-weight: 500;
  font-size: 1.8rem;
}

.benefits__text {
  margin-bottom: 4rem;
}

.benefits__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.benefits__content {
  display: flex;
  padding-top: 2rem;
}

.benefits__list {
  max-width: 57rem;
}

.benefits__list:first-child {
  margin-right: 3rem;
}

.benefits__list-title {
  position: relative;
  margin-bottom: 5rem;
  padding-left: 8rem;
  font-family: "Cormorant SC";
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.4363636364rem + 1.1363636364vw,
    2.8rem
  );
}

.benefits__list-title--pros::before,
.benefits__list-title--cons::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6rem;
  height: 6rem;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.benefits__list-title--pros::before {
  background-image: url(../img/benefits/pros-title.svg);
}

.benefits__list-title--cons::before {
  background-image: url(../img/benefits/cons-title.svg);
}

.benefits__list-item {
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  line-height: 135%;
}

.benefits__list-item:not(:last-child) {
  margin-bottom: 2rem;
}

.benefits__list-item--pros {
  position: relative;
  padding-left: 2.5rem;
}

.benefits__list-item--pros::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1.9rem;
  height: 1.9rem;
  background-image: url(../img/benefits/pros-item.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.registration {
  padding-bottom: 16rem;
}

.registration__wrapper {
  display: flex;
}

.registration__content {
  flex-shrink: 0;
  margin-right: 3rem;
  max-width: 67rem;
}

.registration__text {
  margin-bottom: 4rem;
}

.registration__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.registration__list {
  list-style: decimal;
  padding-left: 2rem;
}

.registration__item {
  line-height: 135%;
}

.registration__item:not(:last-child) {
  margin-bottom: 2rem;
}

.registration__sub-list {
  margin-top: 2rem;
}

.registration__sub-item {
  position: relative;
  padding-left: 1.5rem;
  line-height: 135%;
}

.registration__sub-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-900);
  transform: translateY(-50%);
}

.registration__image {
  z-index: 1;
  flex-shrink: 0;
  max-width: 69.5rem;
}

.registration__image img {
  border-radius: 1.4rem;
}

.registration .decor::before,
.registration .decor::after {
  height: calc(100% + 32rem);
}

.slots {
  padding-bottom: 10rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-700);
}

.slots__title {
  margin-bottom: 4rem;
}

.slots__image {
  position: relative;
  margin-bottom: 4rem;
  max-width: 117rem;
}

.slots__image::after,
.slots__image::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.slots__image::after {
  z-index: 0;
  width: 60rem;
  height: 38rem;
  background-color: rgba(201, 211, 233, 0.76);
  filter: blur(8rem);
}

.slots__image::before {
  z-index: 1;
  width: 27rem;
  height: 14rem;
  background-image: url(../img/slots/button-img.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.slots__text {
  margin-bottom: 6rem;
}

.slots__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.slots__table {
  overflow: auto;
}

.slots__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.slots__table table thead th {
  vertical-align: top;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 135%;
  text-align: start;
}

.slots__table table tbody td {
  vertical-align: top;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  line-height: 135%;
}

.slots__table table tbody td:first-child {
  min-width: 27rem;
}

.games__content {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.games__titles {
  margin-right: 3rem;
  max-width: 57rem;
}

.games__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.games__image {
  position: relative;
  max-width: 57rem;
}

.games__image::after {
  content: "";
  position: absolute;
  right: -3rem;
  top: -3rem;
  width: 14rem;
  height: 13.3rem;
  background-image: -webkit-image-set(url(../img/games/games-decor@2x.png) 2x, url(../img/games/games-decor@1x.png) 1x);
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

.games__table {
  overflow: auto;
  border-radius: 1.4rem;
  background-color: var(--clr-primary-700);
}

.games__table table {
  border-collapse: collapse;
  min-width: 100%;
  color: var(--clr-default-100);
}

.games__table table thead {
  background-color: var(--clr-primary-500);
}

.games__table table thead th {
  padding-top: 1.6rem;
  padding-right: 3rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 135%;
  text-align: start;
}

.games__table table tbody td {
  padding-top: 1.6rem;
  padding-right: 3rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  line-height: 135%;
}

.games__table table tbody td ul {
  padding-left: 2rem;
}

.games__table table tbody td ul li {
  position: relative;
  padding-left: 2rem;
  line-height: 135%;
}

.games__table table tbody td ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.games__table table tbody td ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-100);
}

.games__table table tbody td:first-child {
  min-width: 27rem;
}

.games__table table tbody td:last-child {
  min-width: 40rem;
}

.live__wrapper {
  display: flex;
  align-items: center;
}

.live__content {
  margin-right: 3rem;
  max-width: 47rem;
}

.live__titles {
  margin-bottom: 4rem;
}

.live__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.live__item {
  position: relative;
  padding-left: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--clr-default-800);
}

.live__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-800);
}

.live__item:not(:last-child) {
  margin-bottom: 1rem;
}

.live__image {
  max-width: 67rem;
}

.tournaments__content {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.tournaments__titles {
  margin-right: 3rem;
  max-width: 47rem;
}

.tournaments__text {
  margin-bottom: 7rem;
}

.tournaments__link {
  margin-bottom: 4.5rem;
}

.tournaments__image {
  max-width: 67rem;
}

.tournaments__table {
  overflow: auto;
  border-radius: 1.4rem;
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  background-color: var(--clr-primary-700);
}

.tournaments__table table {
  border-collapse: collapse;
  color: var(--clr-default-100);
}

.tournaments__table table tr {
  border-bottom: 0.1rem solid var(--clr-primary-400);
}

.tournaments__table table td {
  vertical-align: top;
  padding-top: 2rem;
  padding-right: 1.2rem;
  padding-bottom: 2rem;
  padding-left: 1.2rem;
  font-weight: 500;
  line-height: 150%;
}

.tournaments__table table td:first-child {
  min-width: 27rem;
  font-size: 1.8rem;
}

.tournaments__table table td ul li {
  position: relative;
  padding-left: 2rem;
  line-height: 150%;
}

.tournaments__table table td ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.tournaments__table table td ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-100);
}

.bonus__image {
  margin-bottom: 4rem;
  max-width: 117rem;
}

.bonus__text {
  margin-bottom: 6rem;
}

.bonus__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.bonus__table table tr {
  border-bottom: initial;
}

.loyalty__content {
  display: flex;
}

.loyalty__content-left {
  margin-right: 3rem;
  max-width: 67rem;
}

.loyalty__image {
  margin-bottom: 2rem;
  max-width: 67rem;
}

.loyalty__text {
  margin-bottom: 5rem;
}

.loyalty__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.loyalty__link {
  margin-bottom: 5rem;
}

.loyalty__table {
  border-radius: 2.4rem;
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  background-color: var(--clr-primary-700);
}

.loyalty__table table {
  border-collapse: collapse;
  min-width: 48rem;
  color: var(--clr-default-100);
}

.loyalty__table table tr {
  border-bottom: 0.1rem solid var(--clr-primary-400);
}

.loyalty__table table thead th {
  padding: 1.2rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 135%;
  text-align: start;
}

.loyalty__table table thead th:first-child {
  min-width: 27rem;
}

.loyalty__table table tbody td {
  padding: 1.2rem;
  line-height: 135%;
}

.license__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.license__content:not(:last-child) {
  margin-bottom: 6rem;
}

.license__content--vpn {
  flex-direction: row-reverse;
}

.license__content--vpn .license__image {
  margin-right: initial;
  margin-left: 3rem;
}

.license__image {
  flex-shrink: 0;
  margin-right: 3rem;
  max-width: 15rem;
}

.license__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment {
  padding-bottom: 16rem;
}

.payment__wrapper.decor::before,
.payment__wrapper.decor::after {
  height: calc(100% + 32rem);
}

.payment__title {
  margin-bottom: 4rem;
}

.payment__image {
  margin-bottom: 4rem;
  max-width: 117rem;
}

.payment__text {
  margin-bottom: 6rem;
}

.payment__text--bottom {
  margin-bottom: 1.5rem;
}

.payment__table {
  overflow: auto;
  margin-bottom: 2rem;
  border-radius: 1.6rem;
  background-color: var(--clr-primary-700);
}

.payment__table table {
  border-collapse: collapse;
  color: var(--clr-default-100);
}

.payment__table table thead th {
  padding-top: 1.6rem;
  padding-right: 3rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  font-weight: 500;
  font-size: 1.8rem;
  text-align: start;
}

.payment__table table thead th:first-child {
  min-width: 27rem;
}

.payment__table table tbody td {
  vertical-align: top;
  padding-top: 1.6rem;
  padding-right: 3rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;
  line-height: 135%;
}

.payment__list {
  padding-left: 1rem;
}

.payment__item {
  position: relative;
  padding-left: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 135%;
}

.payment__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-900);
}

.service {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  color: var(--clr-default-100);
}

.service::before,
.service::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.service::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-image-set(url(../img/service/bg@2x.jpg) 2x, url(../img/service/bg@1x.jpg) 1x);
}

.service::after {
  right: 0;
  bottom: 0;
  width: 113rem;
  height: 64rem;
  background-image: -webkit-image-set(url(../img/service/avalon-warrior@2x.png) 2x, url(../img/service/avalon-warrior@2x.png) 1x);
}

.service__wrapper {
  display: flex;
  justify-content: space-between;
}

.service__content {
  margin-right: 3rem;
  max-width: 77rem;
}

.service__content:not(:last-child) {
  margin-bottom: 10rem;
}

.service__title {
  margin-bottom: 3rem;
}

.service__title--uppercase {
  text-transform: uppercase;
}

.service__link {
  margin-top: auto;
  margin-bottom: 9rem;
  height: max-content;
}

.faq {
  position: relative;
  padding-bottom: 22rem;
}

.faq__body.decor::before,
.faq__body.decor::after {
  height: calc(100% + 44rem);
}

.faq__title {
  margin-bottom: 4rem;
}

.faq__wrapper {
  border-radius: 0.4rem;
  padding-right: 4rem;
  padding-left: 4rem;
  background-color: var(--clr-primary-700);
}

.faq__wrapper:not(:last-child) {
  margin-bottom: 3rem;
}

.faq__wrapper.active .faq__answer {
  padding-bottom: 2.5rem;
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.faq__question {
  position: relative;
  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
  width: 100%;
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.3090909091rem + 0.9090909091vw,
    2.4rem
  );
  line-height: 135%;
  color: var(--clr-default-100);
}

.faq__answer {
  overflow: hidden;
  padding-right: 15rem;
  max-height: 0;
  box-sizing: content-box;
  font-size: 1.6rem;
  line-height: 135%;
  color: var(--clr-default-100);
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.faq__answer p:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (max-width: 75em) {
  .bonus-btn {
    margin-right: 3rem;
    margin-left: auto;
  }

  .menu {
    margin-right: initial;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    font-size: 2.4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .logo {
    max-width: 8rem;
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header {
    min-height: 12rem;
  }

  .hero {
    padding-top: 10rem !important;
  }

  .registration__content {
    max-width: 55rem !important;
  }

  .slots__image::after {
    width: 50rem;
    height: 25rem;
  }

  .games__titles {
    max-width: 50rem;
  }

  .games__table table tbody td:first-child {
    min-width: 20rem;
  }

  .games__table table tbody td:last-child {
    min-width: 30rem;
  }

  .loyalty__table table {
    min-width: 40rem;
  }

  .service::after {
    display: none;
  }

  .service__wrapper {
    flex-direction: column;
  }

  .service__content-wrapper {
    margin-bottom: 9rem;
  }

  .service__content {
    margin-right: initial;
    max-width: initial;
  }

  .service__link {
    margin-top: initial;
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 8rem;
  }

  .bonus-btn a {
    min-height: 5rem;
  }

  .logo {
    max-width: 6rem;
  }

  .header {
    min-height: 8rem;
  }

  .hero__wrapper {
    flex-direction: column;
  }

  .hero__content {
    margin-right: initial !important;
    max-width: initial !important;
  }

  .hero__text {
    margin-bottom: 3rem;
  }

  .hero__link {
    margin-right: auto;
    margin-left: auto;
  }

  .hero__image {
    margin-right: auto;
    margin-bottom: 6rem !important;
    margin-left: auto;
    max-width: 25rem !important;
  }

  .hero {
    padding-bottom: 10rem !important;
  }

  .info__table table td:first-child {
    min-width: 20rem;
  }

  .registration {
    padding-bottom: 8rem;
  }

  .registration__wrapper {
    flex-direction: column;
  }

  .registration__content {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .registration__image {
    margin-right: auto;
    margin-left: auto;
  }

  .games__content {
    flex-direction: column;
  }

  .games__titles {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .games__table table tbody td:first-child {
    min-width: 15rem;
  }

  .games__table table tbody td:last-child {
    min-width: initial;
  }

  .live__wrapper {
    display: block;
  }

  .live__content {
    margin-right: initial;
    max-width: initial;
  }

  .live__image {
    margin-right: auto;
    margin-bottom: 3rem;
    margin-left: auto;
  }

  .tournaments__titles {
    margin-right: initial;
    max-width: initial;
  }

  .tournaments__text {
    margin-bottom: 5rem;
  }

  .tournaments__link {
    margin-right: auto;
    margin-left: auto;
  }

  .tournaments__image {
    margin-right: auto;
    margin-bottom: 3rem;
    margin-left: auto;
  }

  .loyalty__content-left {
    margin-right: initial;
    max-width: initial;
  }

  .loyalty__image {
    max-width: initial;
  }

  .loyalty__link {
    margin-right: auto;
    margin-left: auto;
  }

  .loyalty__table table {
    min-width: 100%;
  }

  .loyalty__table {
    overflow: auto;
    margin-bottom: 6rem;
  }

  .license__content--vpn .license__image {
    margin-left: initial;
  }

  .license__content {
    flex-direction: column-reverse;
  }

  .license__image {
    margin-right: initial;
  }

  .license__titles {
    margin-bottom: 3rem;
  }

  .payment {
    padding-bottom: 8rem;
  }

  .payment__wrapper.decor::before,
  .payment__wrapper.decor::after {
    height: calc(100% + 16rem);
  }

  .payment__table table thead th:first-child {
    min-width: 20rem;
  }

  .service__content:not(:last-child) {
    margin-bottom: 5rem;
  }

  .service {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .faq {
    padding-bottom: 15rem;
  }

  .faq__body.decor::before,
  .faq__body.decor::after {
    height: calc(100% + 30rem);
  }

  .faq__wrapper:not(:last-child) {
    margin-bottom: 2rem;
  }

  .faq__answer {
    padding-right: initial;
  }
}

@media (max-width: 101.25em) {
  .registration__content {
    flex-shrink: initial;
    max-width: 60rem;
  }

  .registration__image {
    flex-shrink: initial;
  }
}

@media (max-width: 93.75em) {
  .hero__content {
    margin-right: 3rem;
    max-width: 60rem;
  }

  .hero__image::before {
    display: none;
  }

  .hero__image {
    position: initial;
    width: initial;
    height: initial;
    max-width: 54rem;
  }

  .hero {
    padding-top: 20rem;
    padding-bottom: 10rem;
    height: initial;
  }
}

@media (max-width: 47.9375em) {
  .benefits__content {
    flex-direction: column;
  }

  .benefits__list:first-child {
    margin-right: initial;
    margin-bottom: 5rem;
  }

  .benefits__list {
    max-width: initial;
  }

  .slots {
    padding-bottom: 5rem;
  }

  .slots__image::after {
    width: 35rem;
    height: 15rem;
  }

  .slots__image::before {
    width: 20rem;
    height: 10rem;
  }

  .games__table table tbody td ul {
    padding-left: initial;
  }

  .games__table table tbody td:first-child {
    min-width: 10rem;
  }

  .live__titles {
    margin-bottom: 3rem;
  }

  .tournaments__table table td:first-child {
    min-width: 20rem;
  }

  .tournaments__table table {
    min-width: 73rem;
  }

  .loyalty__text {
    margin-bottom: 3rem;
  }

  .license__content:not(:last-child) {
    margin-bottom: 3rem;
  }

  .faq__question {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .section {
    padding-top: 5rem;
  }

  .main__link {
    margin-right: auto;
    margin-left: auto;
  }

  .bonus-btn {
    margin-right: initial;
    margin-left: initial;
  }

  .hero__title {
    text-align: center;
  }

  .hero {
    padding-top: 8rem !important;
    padding-bottom: 6rem !important;
  }

  .info__subtitle {
    margin-bottom: 3rem;
  }

  .info__table table td:first-child {
    min-width: 15rem;
  }

  .info__table table td {
    min-width: 50rem;
  }

  .info__table {
    padding-top: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
  }

  .benefits__text {
    margin-bottom: 3rem;
  }

  .benefits__list-title {
    margin-bottom: 4rem;
  }

  .registration {
    padding-bottom: 5rem;
  }

  .registration__text {
    margin-bottom: 3rem;
  }

  .slots__title {
    margin-bottom: 3rem;
  }

  .slots__image::after {
    width: 30rem;
    height: 10rem;
  }

  .slots__image {
    margin-bottom: 3rem;
  }

  .slots__text {
    margin-bottom: 3rem;
  }

  .slots__table table thead th {
    padding-top: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
  }

  .slots__table table tbody td:first-child {
    min-width: 20rem;
  }

  .slots__table table tbody td {
    padding-top: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
  }

  .games__content {
    margin-bottom: 3rem;
  }

  .games__image::after {
    top: -2rem;
    width: 10rem;
    height: 8rem;
  }

  .games__table table thead th {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
  }

  .games__table table tbody td ul li {
    padding-left: 1rem;
  }

  .games__table table tbody td {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
  }

  .tournaments__content {
    margin-bottom: 3rem;
  }

  .tournaments__table {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .bonus__image {
    margin-bottom: 3rem;
  }

  .bonus__text {
    margin-bottom: 3rem;
  }

  .loyalty__table table thead th:first-child {
    min-width: 11rem;
  }

  .loyalty__table {
    padding-top: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
  }

  .payment {
    padding-bottom: 5rem;
  }

  .payment__wrapper.decor::before,
  .payment__wrapper.decor::after {
    height: calc(100% + 10rem);
  }

  .payment__title {
    margin-bottom: 3rem;
  }

  .payment__image {
    margin-bottom: 3rem;
  }

  .payment__text {
    margin-bottom: 3rem;
  }

  .payment__table table thead th {
    padding-right: 2rem;
    padding-left: 1.6rem;
  }

  .payment__table table tbody td {
    padding-right: 2rem;
    padding-left: 1.6rem;
  }

  .service__content-wrapper {
    margin-bottom: 7rem;
  }

  .faq {
    padding-bottom: 5rem;
  }

  .faq__body.decor::before,
  .faq__body.decor::after {
    height: calc(100% + 10rem);
  }

  .faq__wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .faq__wrapper.active .faq__answer {
    padding-bottom: 2rem;
  }

  .faq__wrapper {
    padding-left: 2rem;
  }
}

@media (hover: hover) {
  .main__link:hover {
    background-color: #e7471a;
    transition: background-color var(--transition);
  }

  .bonus-btn a:hover {
    background-color: #e7471a;
    transition: background-color var(--transition);
  }

  .menu__item:hover::before {
    width: 100%;
    transition: width 0.3s;
  }
}