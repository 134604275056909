.license {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 6rem;

      @media (max-width: em(767)) {
        margin-bottom: 3rem;
      }
    }

    &--vpn {
      flex-direction: row-reverse;

      .license__image {
        margin-right: initial;
        margin-left: 3rem;

        @media (max-width: em(992)) {
          margin-left: initial;
        }
      }
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    flex-shrink: 0;
    margin-right: 3rem;
    max-width: 15rem;

    @media (max-width: em(992)) {
      margin-right: initial;
    }
  }

  &__titles {
    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

