.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 16rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }
}

.title {
  margin-bottom: 2.5rem;
  font-family: "Cormorant SC";
  font-weight: 700;
  font-size: fluid-font(4.8rem, 2.4rem);
}

.description {
  font-weight: 400;
  line-height: 135%;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 26rem;
  min-height: 7rem;
  max-width: 26rem;
  font-family: "Cormorant SC";
  font-weight: 500;
  font-size: fluid-font(2.4rem, 1.8rem);
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-500);
  transition: background-color var(--transition);

  &::after {
    content: "";
    position: absolute;
    left: -8px;
    top: -29px;
    width: calc(100% + 1.5rem);
    height: calc(100% + 7.5rem);
    background-image: url(../img/ui/btn-bg.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }


  @media (hover: hover) {
    &:hover {
      background-color: #e7471a;
      transition: background-color var(--transition);
    }
  }

  @media (max-width: em(575)) {
    margin-right: auto;
    margin-left: auto;
  }
}

.decor {
  position: relative;

  &:before,
  &::after {
    content: "";
    position: absolute;
    left: -13.5rem;
    top: -16rem;
    width: 9rem;
    height: calc(100% + 16rem);
    background-image: url(../img/ui/decor.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::after {
    left: initial;
    right: -13.5rem;
  }
}

.image-border {
  img {
    border: 0.2rem solid var(--clr-default-300);
    border-radius: 1.4rem;
  }
}
