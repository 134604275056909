.loyalty {
  &__wrapper {
  }

  &__title {
  }

  &__content {
    display: flex;
  }

  &__content-left {
    margin-right: 3rem;
    max-width: 67rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      max-width: initial;
    }
  }

  &__image {
    margin-bottom: 2rem;
    max-width: 67rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__table {
    border-radius: 2.4rem;
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
      min-width: 48rem;
      color: var(--clr-default-100);

      tr {
        border-bottom: 0.1rem solid var(--clr-primary-400);
      }

      thead {
        th {
          padding: 1.2rem;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 135%;
          text-align: start;

          &:first-child {
            min-width: 27rem;

            @media (max-width: em(575)) {
              min-width: 11rem;
            }
          }
        }
      }

      tbody {
        td {
          padding: 1.2rem;
          line-height: 135%;
        }
      }

      @media (max-width: em(1200)) {
        min-width: 40rem;
      }

      @media (max-width: em(992)) {
        min-width: 100%;
      }
    }

    @media (max-width: em(992)) {
      overflow: auto;
      margin-bottom: 6rem;
    }

    @media (max-width: em(575)) {
      padding-top: 2rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
    }
  }
}
