.bonus {
  &__image {
    margin-bottom: 4rem;
    max-width: 117rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-bottom: 6rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    table {
      tr {
        border-bottom: initial;
      }
    }
  }
}

