.slots {
  padding-bottom: 10rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-700);

  @media (max-width: em(767)) {
    padding-bottom: 5rem;
  }

  &__title {
    margin-bottom: 4rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 4rem;
    max-width: 117rem;

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    &::after {
      z-index: 0;
      width: 60rem;
      height: 38rem;
      background-color: rgba(201, 211, 233, 0.76);
      filter: blur(8rem);

      @media (max-width: em(1200)) {
        width: 50rem;
        height: 25rem;
      }

      @media (max-width: em(767)) {
        width: 35rem;
        height: 15rem;
      }

      @media (max-width: em(575)) {
        width: 30rem;
        height: 10rem;
      }
    }

    &::before {
      z-index: 1;
      width: 27rem;
      height: 14rem;
      background-image: url(../img/slots/button-img.svg);
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(767)) {
        width: 20rem;
        height: 10rem;
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__text {
    margin-bottom: 6rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    table {
      border-collapse: collapse;
      min-width: 100%;

      thead {
        th {
          vertical-align: top;
          padding-top: 1.6rem;
          padding-right: 1.6rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 135%;
          text-align: start;

          @media (max-width: em(575)) {
            padding-top: 1.2rem;
            padding-right: 1.2rem;
            padding-bottom: 1.2rem;
            padding-left: 1rem;
          }
        }
      }
      tbody {
        td {
          vertical-align: top;
          padding-top: 1.6rem;
          padding-right: 1.6rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          line-height: 135%;

          &:first-child {
            min-width: 27rem;

            @media (max-width: em(575)) {
              min-width: 20rem;
            }
          }

          @media (max-width: em(575)) {
            padding-top: 1.2rem;
            padding-right: 1.2rem;
            padding-bottom: 1.2rem;
            padding-left: 1rem;
          }
        }
      }
    }
  }
}
