.logo {
  z-index: 1;
  margin-right: 2rem;
  max-width: 11rem;

  @media (max-width: em(1200)) {
    max-width: 8rem;
  }

  @media (max-width: em(992)) {
    max-width: 6rem;
  }
}