.faq {
  position: relative;
  padding-bottom: 22rem;

  @media (max-width: em(992)) {
    padding-bottom: 15rem;
  }

  @media (max-width: em(575)) {
    padding-bottom: 5rem;
  }

  &__body {
    &.decor {
      &::before, &::after {
        height: calc(100% + 44rem);

        @media (max-width: em(992)) {
          height: calc(100% + 30rem);
        }

        @media (max-width: em(575)) {
          height: calc(100% + 10rem);
        }
      }
    }
  }

  &__title {
    margin-bottom: 4rem;
  }

  &__wrapper {
    border-radius: 0.4rem;
    padding-right: 4rem;
    padding-left: 4rem;
    background-color: var(--clr-primary-700);

    &:not(:last-child) {
      margin-bottom: 3rem;

      @media (max-width: em(992)) {
        margin-bottom: 2rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 1.5rem;
      }
    }

    &.active {
      .faq__answer {
        padding-bottom: 2.5rem;
        transition: max-height var(--transition),
          padding-bottom var(--transition);

        @media (max-width: em(575)) {
          padding-bottom: 2rem;
        }
      }
    }

    @media (max-width: em(575)) {
      padding-left: 2rem;
    }
  }

  &__question {
    position: relative;
    padding-top: 2.5rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
    width: 100%;
    font-weight: 500;
    font-size: fluid-font(2.4rem, 1.6rem);
    line-height: 135%;
    color: var(--clr-default-100);

    @media (max-width: em(767)) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &__answer {
    overflow: hidden;
    padding-right: 15rem;
    max-height: 0;
    box-sizing: content-box;
    font-size: 1.6rem;
    line-height: 135%;
    color: var(--clr-default-100);
    transition: max-height var(--transition), padding-bottom var(--transition);

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: em(992)) {
      padding-right: initial;
    }
  }
}
