.tournaments {

  &__content {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__titles {
    margin-right: 3rem;
    max-width: 47rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 7rem;

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
    }
  }

  &__link {
    margin-bottom: 4.5rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__image {
    max-width: 67rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-bottom: 3rem;
      margin-left: auto;
    }
  }

  &__table {
    overflow: auto;
    border-radius: 1.4rem;
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
      color: var(--clr-default-100);

      tr {
        border-bottom: 0.1rem solid var(--clr-primary-400);
      }

      td {
        vertical-align: top;
        padding-top: 2rem;
        padding-right: 1.2rem;
        padding-bottom: 2rem;
        padding-left: 1.2rem;
        font-weight: 500;
        line-height: 150%;

        &:first-child {
          min-width: 27rem;
          font-size: 1.8rem;

          @media (max-width: em(767)) {
            min-width: 20rem;
          }
        }

        ul {
          li {
            position: relative;
            padding-left: 2rem;
            line-height: 150%;

            &:not(:last-child) {
              margin-bottom: 1rem;
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 1rem;
              border-radius: 50%;
              width: 0.3rem;
              height: 0.3rem;
              background-color: var(--clr-default-100);
            }
          }
        }
      }

      @media (max-width: em(767)) {
        min-width: 73rem;
      }
    }

    @media (max-width: em(575)) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
}

