.games {
  &__wrapper {
  }

  &__content {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__titles {
    margin-right: 3rem;
    max-width: 57rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__title {
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    position: relative;
    max-width: 57rem;

    &::after {
      content: "";
      position: absolute;
      right: -3rem;
      top: -3rem;
      width: 14rem;
      height: 13.3rem;
      background-image: -webkit-image-set(
        url(../img/games/games-decor@2x.png) 2x,
        url(../img/games/games-decor@1x.png) 1x
      );
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;

      @media (max-width: em(575)) {
        top: -2rem;
        width: 10rem;
        height: 8rem;
      }
    }
  }

  &__table {
    overflow: auto;
    border-radius: 1.4rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
      min-width: 100%;
      color: var(--clr-default-100);

      thead {
        background-color: var(--clr-primary-500);

        th {
          padding-top: 1.6rem;
          padding-right: 3rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 135%;
          text-align: start;

          @media (max-width: em(575)) {
            padding-top: 1rem;
            padding-right: 2rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
          }
        }
      }

      tbody {
        td {
          padding-top: 1.6rem;
          padding-right: 3rem;
          padding-bottom: 1.6rem;
          padding-left: 3rem;
          line-height: 135%;

          ul {
            padding-left: 2rem;

            li {
              position: relative;
              padding-left: 2rem;
              line-height: 135%;

              &:not(:last-child) {
                margin-bottom: 1rem;
              }

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 1rem;
                width: 0.3rem;
                height: 0.3rem;
                background-color: var(--clr-default-100);
              }

              @media (max-width: em(575)) {
                padding-left: 1rem;
              }
            }

            @media (max-width: em(767)) {
              padding-left: initial;
            }
          }

          &:first-child {
            min-width: 27rem;

            @media (max-width: em(1200)) {
              min-width: 20rem;
            }

            @media (max-width: em(992)) {
              min-width: 15rem;
            }

            @media (max-width: em(767)) {
              min-width: 10rem;
            }
          }

          &:last-child {
            min-width: 40rem;

            @media (max-width: em(1200)) {
              min-width: 30rem;
            }

            @media (max-width: em(992)) {
              min-width: initial;
            }
          }

          @media (max-width: em(575)) {
            padding-top: 1rem;
            padding-right: 2rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
          }
        }
      }
    }
  }
}
